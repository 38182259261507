const getProvider = () => {
    if ('phantom' in window) {
      const provider = window.phantom?.solana;
      if (provider?.isPhantom) {
        return provider;
      }
    }
    console.error('Phantom Wallet is not available. Please install it from https://phantom.app/');
    return null;
  };

export default async function authMiddleware(to, from, next) {
    const provider = getProvider();
    if (!provider) {
        return next({ name: 'home' });
    }
    const isWalletConnected = window.solana && window.solana.isConnected;
    const accessToken = localStorage.getItem('accessToken');
    
    switch (to.name) {
        case 'LoginAndRegister':
            if (isWalletConnected && accessToken == 'undefined' && accessToken == '') {
                return next({ name: 'Dashboard' });
            } else if (isWalletConnected) {
                return next();
            } else {
                return next({ name: 'Home' });
            }
        case 'build-your-bera':
            if (isWalletConnected && accessToken != 'undefined' && accessToken != '' && accessToken != null) {
                return next();
            } else if (isWalletConnected) {
                return next({ name: 'LoginAndRegister' });
            } else {
                return next({ name: 'Home' });
            }

        case 'Dashboard':
            if (isWalletConnected && accessToken != 'undefined' && accessToken != '' && accessToken != null) {
                return next();
            } else if (isWalletConnected) {
                return next({ name: 'LoginAndRegister' });
            } else {
                return next({ name: 'Home' });
            }
            
        case 'Home':
        case 'index.html':
            if (isWalletConnected && accessToken != 'undefined' && accessToken != '' && accessToken != null) {
                return next({ name: 'Dashboard' });
            } else if (isWalletConnected) {
                return next({ name: 'LoginAndRegister' });
            } else {
                return next();
            }

        default:
            return next({ name: 'Home' });
    }
}

