<template>
  <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
    <!-- Columna de selectores visuales - Se convierte en una sola columna en móviles -->
    <div class="bg-white p-6 rounded-md shadow-md h-auto md:h-4/5 lg:h-4/5 overflow-auto custom-scrollbar">
      <h2 class="text-xl font-bold mb-6">Build your CTO Bera</h2>
      <!-- Iteración sobre cada categoría de personalización -->
      <div v-for="(layer, key) in NFT_LAYERS" :key="key" class="mb-8 ">
        <h3 class="text-lg font-semibold mb-2 text-gray-700 my-6">
          <!-- Reducido el margen -->
          {{ layer.name }}
        </h3>

        <!-- Grid más compacto con menos columnas -->
        <div class="grid grid-cols-5 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 gap-6">
          <NFTOptionCard
            v-for="item in layer.items"
            :key="item.id"
            :image-url="item.url"
            :name="item.name"
            :is-selected="selections[key] === item.url"
            @select="handleSelection(key, item.url)"
          />
        </div>
      </div>
    </div>

    <div class="bg-white p-6 rounded-md shadow-md h-auto md:h-4/5 lg:h-4/5">
      <h2 class="text-xl font-bold mb-6">Preview</h2>
      <!-- Contenedor del canvas con aspect-ratio cuadrado -->
      <div
        class="relative w-full aspect-square bg-gray-200 rounded-lg mb-4 h-auto"
      >
        <canvas ref="previewCanvas" class="w-full h-full"></canvas>
      </div>

      <!-- Grupo de botones de acción -->
      <div class="space-y-4 mt-10">
        <button
          @click="handleDownload"
          class="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition-colors disabled:opacity-50"
          :disabled="!isComplete"
        >
          Download JPEG
        </button>

        <div class="flex gap-4">
          <button
            @click="shareNative"
            class="flex-1 bg-gray-800 text-white py-2 px-4 rounded-md hover:bg-gray-700 transition-colors disabled:opacity-50 flex items-center justify-center gap-2"
            :disabled="!isComplete"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-5 h-5"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"
              />
            </svg>
            Share
          </button>

          <button
            @click="showXDialog = true"
            class="bg-black text-white py-2 px-4 rounded-md hover:bg-gray-800 transition-colors disabled:opacity-50 flex items-center justify-center"
            :disabled="!isComplete"
          >
            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 24 24">
              <path
                d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    v-if="showXDialog"
    class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
  >
    <div class="bg-white rounded-lg p-6 max-w-md w-full">
      <h3 class="text-lg font-bold mb-4">Share on X</h3>

      <div class="space-y-4">
        <p class="text-sm text-gray-600">
          In order to share on X, follow the steps below:
        </p>

        <ol class="list-decimal pl-4 space-y-2 text-sm text-gray-600">
          <li>Download the JPEG below</li>
          <li>
            Share the image on the X window that pops up after downloading
          </li>
        </ol>

        <div class="space-y-3 mt-6">
          <button
            @click="handleDownloadAndShare"
            class="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition-colors"
          >
            Download
          </button>

          <button
            @click="showXDialog = false"
            class="w-full border border-gray-300 text-gray-700 py-2 px-4 rounded-md hover:bg-gray-50 transition-colors"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script setup>
import { ref, onMounted, watch } from "vue";
import NFTOptionCard from "./NTFOptionCard";
import { NFT_LAYERS } from "../config/layers";
import { useNFTComposition } from "../composables/useNFTComposition";

// Referencias y estado
const previewCanvas = ref(null);
const showXDialog = ref(false);

// Inicializar el composable de NFT
const {
  selections,
  isComplete,
  composeImage,
  downloadImage,
  initializeCanvas,
} = useNFTComposition();

// Función para obtener la imagen del canvas en formato File
const getNFTImage = async () => {
  const imageDataUrl = previewCanvas.value.toDataURL("image/png");
  const response = await fetch(imageDataUrl);
  const blob = await response.blob();
  return new File([blob], "custom-cto-bera.png", { type: "image/png" });
};

// Función para manejar la selección de opciones con animación
const handleSelection = async (key, url) => {
  // Evitar reselección del mismo item
  if (selections.value[key] === url) return;
  // Actualizar la selección
  selections.value[key] = url;
};

// Función para compartir usando Web Share API
const shareNative = async () => {
  if (!isComplete.value) return;

  try {
    const file = await getNFTImage();
    const shareData = {
      title: "My custom CTO Bera",
      text: "Let's fkn CTO!",
      files: [file],
    };

    if (navigator.canShare && navigator.canShare(shareData)) {
      await navigator.share(shareData);
    } else {
      const simpleShareData = {
        title: "My custom CTO Bera",
        text: "Let's fkn CTO!",
      };
      await navigator.share(simpleShareData);
    }
  } catch (error) {
    console.error("Error while sharing:", error);
  }
};

// Función para manejar la descarga y compartir en X
const handleDownloadAndShare = async () => {
  try {
    await downloadImage(previewCanvas.value);

      const text = "I just made my custom @Bera_Coin CTO Bera!";
    const shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      text
    )}`;
    window.open(shareUrl, "Share on X", "width=550,height=420");

    showXDialog.value = false;
  } catch (error) {
    console.error("Error downloading and sharing:", error);
  }
};

// Función para manejar la descarga
const handleDownload = () => {
  if (isComplete.value) {
    downloadImage(previewCanvas.value);
  }
};

// Inicialización del componente
onMounted(async () => {
  const canvas = previewCanvas.value;
  canvas.width = 800;
  canvas.height = 800;

  // Inicializar el canvas con el cuerpo base
  await initializeCanvas(canvas);
});

// Observador para actualizar el canvas cuando cambian las selecciones
watch(
  selections,
  () => {
    if (previewCanvas.value) {
      composeImage(previewCanvas.value);
    }
  },
  { deep: true }
);
</script>
  
  <style>
/* Animaciones personalizadas */
@keyframes bounce-once {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

.animate-bounce-once {
  animation: bounce-once 0.3s ease-in-out;
}

/* Transiciones suaves para hover y estados activos */
.transition-transform {
  transition: transform 0.2s ease-in-out;
}
/* Personalización de la barra de scroll */
.custom-scrollbar {
  /* Garantiza que el scroll funcione en Firefox */
  scrollbar-width: thin;
  scrollbar-color: rgba(156, 163, 175, 0.5) transparent;
}

/* Estilos para Chrome, Safari y Edge */
.custom-scrollbar::-webkit-scrollbar {
  width: 6px; /* Barra más fina */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(156, 163, 175, 0.5);
  border-radius: 20px;
  border: transparent;
}

/* Hover estado para la barra */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(156, 163, 175, 0.7);
}
</style>