<script setup>
import RaffleComponent from './RaffleComponent.vue';

import { onMounted, ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import User from '../models/User';
import burnedBera from '../assets/images/burnedBera.jpg'

const router = useRouter();
const user = ref(null);
const username = ref("");
const userLevel = ref("");
const tokenBalance = ref("");

const burnerAddresses =
[
    "0xa5928efde9a95dd3b535b857c05e1d7c78fa49e4".toLowerCase(),
    "0x33DF8400B73136c3eD3D658a4384353f5d903Eab".toLowerCase(),
    "0x49b14190107247C50d752F7C7697607735D89C5c".toLowerCase(),
    "0x39786c76037037FA6AD759780E501B65Fc8b5b66".toLowerCase(),
    "0x18A428CE0c31584a21CF8ceFDb8849d8013E1994".toLowerCase(),
    "0x3EF02b7922978a1200936de10e23C21499d795BE".toLowerCase(),
    "0xAE5d235c7c24F06a14F25f3FA8906d536fFeBb57".toLowerCase(),
    "0x67C77C910084237a12c3378A68D164Ec5917D6d1".toLowerCase()
];

const raffles = {
    "launch":
    {
        id: "launch",
        title: "Join the Beracoin lottery",
        description: "We are doing weekly raffles of NFTs, WL and more",
        stepsDescription: "Check our twitter or discord to see the current raffles",
        steps: [
            "1. Tweet a screenshot of your Bera Passport.",
            "2. Connect your twitter account.",
            "3. Paste the link to your tweet below.",
            "4. Hold at least 10k $BERA tokens. You need to keep holding until the raffle results are anounced.",
            "5. Click join raffle."
        ],
        tweetPlaceholder: "Paste your tweet link here...",
    },
    "boogaberas":
    {
        id: "boogaberas",
        title: "Join the Boogaberas speshal lottery",
        description: "We are raffling <a target='blank' class='text-orange-400' href='https://opensea.io/assets/arbitrum/0x6ba79f573edfe305e7dbd79902bc69436e197834/4390'>Boogabera 4390</a>",
        stepsDescription: "Follow the steps below to join",
        steps: [
            "1. Tweet a screenshot of your Bera Passport.",
            "2. Connect your twitter account.",
            "3. Paste the link to your tweet below.",
            "4. Hold at least 100k $BERA tokens. You need to keep holding until the raffle results are anounced (September 23).",
            "5. Click join raffle.",
            "6. Follow <a target='blank' href='https://twitter.com/Bera_Coin'>@Bera_Coin</a> on Twitter.",
            "7. Like and retweet <a target='blank' class='text-orange-400' href='https://x.com/Bera_Coin/status/1834942864859504937'>this tweet</a>."
        ],
        tweetPlaceholder: "Paste your tweet link here...",
        // backgroundUrl: "../assets/images/honeybg.jpg"
        backgroundUrl: "https://i.seadn.io/s/raw/files/6b3b6ef08636f5eb0405dbaea3da18cd.png?auto=format&dpr=1&w=1000"
    },
    "fcfs":
    {
        id: "fcfs",
        title: "You qualify for a FCFS mint. GL lol",
        description: "Sumbit your wallet address below",
        tweetPlaceholder: "Paste your wallet address here",
        stepsDescription: "",
        steps: "", 
        joinMessage: "Submit"
    },
    "free-mint":
    {
        id: "free-mint",
        title: "You qualify for a free NFT airdrop. WTF",
        description: "Sumbit your wallet address below",
        tweetPlaceholder: "Paste your wallet address here",
        stepsDescription: "",
        steps: "",
        joinMessage: "Submit"
    },
    "raffle-wl":
    {
        id: "whitelists",
        title: "Beracoin raffle",
        description: "When we get WL from friends we will use this raffle to distribute some of them. Follow the steps below:",
        tweetPlaceholder: "Paste your EVM wallet address here",
        stepsDescription: "",
        steps: [
            "Hold some $BERA. Every 10k $BERA you hold gives you 1 ticket.",
            "Sumbit your wallet address below",
            "You will be re entered in future raffles so long as you keep holding over 10k $BERA."
        ],
        joinMessage: "Submit"
    },
}

// NFT Balances
const nftCollections = ref([]);
const filteredCollections = computed(() => {
    return nftCollections.value.filter(collection => collection.balance > 0);
});


const evmAddressesList = ref([]);
const solAddressesList = ref([]);
const userInstance = new User();
const twitterUser = ref("");
const newUsername = ref("");
const errorMessageUserUpdate = ref("");
// const errorMessageEnterRaffle = ref("");
const errorMessageLinkAddress = ref("");
const successLinkedAddress = ref("");
const isVisible = ref(true);
const enterRaffleTweet = ref("");
const enteredRaffle = ref("");
const isBurner = ref(false);

const userLevelClass = computed(() => {
    switch (userLevel.value) {
        case "Having Fun Staying Poor":
            return "one";
        case "Whatever McDonalds worker":
            return "two";
        case "Bera FanBoy":
            return "three";
        case "Fucking Nerd":
            return "four";
        case "Giga Retarded": 
            return "five";
        default: return "";
    }
});

const eyeIconClass = computed(() => {
      return isVisible.value ? 'fa fa-eye' : 'fa fa-eye-slash';
    });

const toggleVisibility = () => {
      isVisible.value = !isVisible.value;
    };

const shortenAddressEvm = (address) => {
    return address;
};
const shortenAddressSol = (address) => {
    return address;
};

const shortenedEvmAddressesList = computed(() => {
    return evmAddressesList.value.map(address => shortenAddressEvm(address));
});

const shortenedSolAddressesList = computed(() => {
    return solAddressesList.value.map(address => shortenAddressSol(address));
});

// const bearRebasesCollections = ["Bit Bears", "Band Bears", "Baby Bears", "Boo Bears", "Bond Bears"];
const getUser = async () => {
    user.value = await userInstance.getUser();
    if (user.value == null) {
        router.push({ name: 'Home' });
    }

    if (user.value && user.value.nft_balances) {
        userLevel.value = user.value.level;
        tokenBalance.value = user.value.beracoin_balance;
        evmAddressesList.value = user.value.evm_addresses ? user.value.evm_addresses.map(addrObj => addrObj.address) : [];
        solAddressesList.value = user.value.solana_addresses ? user.value.solana_addresses.map(addrObj => addrObj.address) : [];
        username.value = user.value.username === '' ? shortenAddressSol(user.value.address) : user.value.username;

        // Populate nftCollections
        nftCollections.value = user.value.nft_balances.map(nft => ({
            collectionName: nft.collection,
            balance: nft.balance || 0,
            image: nft.image || '',
        }));

        if (user.value.twitter_username) {
            twitterUser.value = '@' + user.value.twitter_username;
        } else {
            twitterUser.value = null;
        }

        evmAddressesList.value.forEach(address => {
            if (burnerAddresses.includes(address.toLowerCase())) {
                isBurner.value = true;
            }
        });
    } else {
        router.push({ name: 'Home' });
    }
};

// const deleteUser = async () => {
//     try {
//         await userInstance.deleteUser();
//         router.push({ name: 'Home' });
//     } catch (error) {
//         console.log('error delete user')
//         errorMessageUserUpdate.value = 'Something was wrong deleting user'
//     }
// };

const logout = async () => {
    try {
        await userInstance.logout();
        router.push({ name: 'Home' });
    } catch (error) {
        console.log('logout failed')
        errorMessageUserUpdate.value = 'Logout failed'
    }
};

const linkEvmAddress = async () => {
    try {
        const address = await userInstance.addEvmAddress();
        evmAddressesList.value.push(address);
        successLinkedAddress.value = "Address linked successfully";
        errorMessageLinkAddress.value = "";
        await getUser();    // Update user with new data
    } catch (error) {
        errorMessageLinkAddress.value = error.message;
    }
};

const removeEvmAddress = async () => {
    try {
        const address = await userInstance.removeEvmAddress();
        evmAddressesList.value.pop();
        successLinkedAddress.value = `Address ${address} removed successfully`;
        errorMessageLinkAddress.value = "";
        await getUser();    // Update user with new data
    } catch (error) {
        errorMessageLinkAddress.value = error.message;
    }
};


const linkSolAddress = async () => {
    try {
        const address = await userInstance.addSolAddress();
        solAddressesList.value.push(address);
        successLinkedAddress.value = `Address ${address} linked successfully`;
        errorMessageLinkAddress.value = "";
        await getUser();    // Update user with new data
    } catch (error) {
        errorMessageLinkAddress.value = error.message;
    }
};

const removeSolAddress = async () => {
  try {
    let address = await userInstance.removeSolAddress();
    solAddressesList.value.pop();
    successLinkedAddress.value = `Address ${address} removed successfully`;
    errorMessageLinkAddress.value = "";
    await getUser(); // Update user with new data
  } catch (error) {
    errorMessageLinkAddress.value = error.message;
  }
};


const updateUsername = async () => {
    errorMessageUserUpdate.value = "";
    try {
        if (newUsername.value.trim() === "") {
            errorMessageUserUpdate.value = "Username cannot be empty";
            return;
        }
        // Validate the username input with the regex ^[a-zA-Z0-9][a-zA-Z0-9._@#$&-]{2,28}[a-zA-Z0-9]$
        if (!/^[a-zA-Z0-9][a-zA-Z0-9._@#$&\s-]{2,28}[a-zA-Z0-9]$/.test(newUsername.value)) {
            errorMessageUserUpdate.value = 'Username must be between 4 and 30 characters and can only contain letters, numbers, and special characters . _ @ # $ & -';
            return;
        }
        await userInstance.updateUsername(newUsername.value);
        username.value = newUsername.value;
        newUsername.value = "";
    } catch (error) {
        errorMessageUserUpdate.value = error.message;
    }
};


const getRaffleEntry = async () => {
    const raffleEntry = await userInstance.getRaffleEntry();
    if (raffleEntry != null && raffleEntry.launch) {
        enteredRaffle.value = true;
        enterRaffleTweet.value = raffleEntry.launch;
    }
    return "";
};

onMounted(async () => {
    await getUser();
    await getRaffleEntry();
});

</script>

<template>
    <div id="app" class="flex flex-col h-auto !my-24 ">
        <div class="flex-grow flex justify-center items-center max-w-full ">
            <div class=" mob flex flex-col gap-8 w-auto h-auto max-w-full">
                <div id="passport" class="card rounded-md">
                    <div class="top-section">
                        <div class="left">
                            <div class="title">BERA PASSPORT</div>
                            <div class="subtitle">
                                {{ isBurner ? "GAY LIFETIME MEMBERSHIP" : "GAY MEMBERSHIP" }}
                            </div>

                        </div>
                        <div class="right">
                            <div class="rainbow"></div>
                        </div>
                    </div>
                    <div class="separator"></div>
                    <div class="bottom-section">
                        <div class="profile-picture"></div>
                        <div class="details">
                            <div class="row">
                                <p class="text-lg">
                                    Ser: <span class="name">{{ username }}</span>
                                </p>
                            </div>
                            <div class="row">
                                <p class="text-lg">
                                    Bera score: <span :class="['score', 'text-sm', userLevelClass]">{{ userLevel
                                        }}</span>
                                </p>
                            </div>
                            <div class="row flex justify-between">
                                <p class="text-lg">NFT holdings:</p>
                                <div class="mt-1" @click="toggleVisibility">
                                    <!-- Controlamos la clase del ícono dinámicamente -->
                                    <i :class="eyeIconClass" aria-hidden="true" style="cursor: pointer; font-size: 18px;" title="Hide/Show balances"></i>
                                </div>
                            </div>

                            <div class="flex gap-2" id="badges">
                                <div
                                    v-for="(collection, index) in filteredCollections"
                                    :key="index"
                                    :title="collection.collectionName"
                                    class="badge relative"
                                >
                                    <div
                                        :style="{ backgroundImage: `url(${collection.image})` }"
                                        class="badge-background transition-all duration-300"
                                        :class="{ 'grayscale': collection.balance == 0, 'grayscale-0': collection.balance > 0 }"
                                    ></div>
                                    <span v-if="isVisible" class="badge-number absolute z-10">
                                        {{ collection.balance }}
                                    </span>
                                </div>
                                <div
                                    v-if="isBurner" 
                                    title="Berachain CTO burner"
                                    class="badge relative"
                                >
                                    <div
                                        :style="{ backgroundImage: `url(${burnedBera})` }"
                                        
                                        class="badge-background transition-all duration-300"
                                        
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="add-address" class="w-full flex-row mb-5 mt-8 card rounded-md ">
                    <div class="my-3" style="display:grid">
                    <router-link
                    :to="{ path: '/build-your-bera' }"
                    class="sm:w-full w-3/4 mt-20 bg-blue-300 text-black px-6 py-5 rounded font-bold hover:bg-blue-400 transition duration-300 text-md disabled:opacity-50 "
                    >Build your CTO Bera</router-link>
                    </div>
                </div>

                <!-- Use the RaffleComponent and pass props -->
                
                <RaffleComponent 
                    :title="raffles['raffle-wl'].title"
                    :description="raffles['raffle-wl'].description"
                    :stepsDescription="raffles['raffle-wl'].stepsDescription"
                    :steps="raffles['raffle-wl'].steps"
                    :twitterUser="twitterUser"
                    :raffleId="raffles['raffle-wl'].id"
                    :tweetPlaceholder="raffles['raffle-wl'].tweetPlaceholder"
                    :hasTwitter="raffles['raffle-wl'].hasTwitter"
                />

                <div id="add-address" class="w-full flex-row mb-5 mt-8 card rounded-md ">
                    <p class="text-center text-orange-400 py-5">In order to add/remove an address, switch to that address on your wallet. Then click add/remove address.</p>
                    <div class="w-full flex justify-center sm:gap-4 md:gap-4 gap-6">
                            <button @click="linkEvmAddress"
                            class="bg-orange-400 text-white px-6 py-2 rounded font-bold hover:bg-orange-500 transition duration-300 sm:text-sm text-md h-14">
                            ADD EVM ADDRESS
                        </button>
                        <button @click="linkSolAddress"
                        class="bg-orange-400 text-white px-6 py-2 rounded font-bold hover:bg-orange-500 transition duration-300 sm:text-sm text-md h-14">
                        ADD SOL ADDRESS
                        </button>
                    </div>
                    <p class="text-center text-red-400  py-5">Warning: Removing an address also removes your participation on any current raffles. You will have to re join the raffles after removing your address.</p>
                    <div class="my-3 py-4 w-full flex justify-center sm:gap-4 md:gap-4 gap-6">
                        <button @click="removeEvmAddress"
                            class="bg-red-400 text-white px-6 py-2 rounded font-bold hover:bg-red-500 transition duration-300 sm:text-sm text-md h-14">
                            REMOVE EVM ADDRESS
                        </button>
                        <button @click="removeSolAddress"
                            class="bg-red-400 text-white px-6 py-2 rounded font-bold hover:bg-red-500 transition duration-300 sm:text-sm text-md h-14">
                            REMOVE SOL ADDRESS
                        </button>
                    </div>
                    <div class="my-3" style="display:grid">
                        <a href="#sol-addresses"
                            class="sm:w-full w-3/4 mt-20 bg-blue-300 text-black px-6 py-5 rounded font-bold hover:bg-blue-400 transition duration-300 text-md disabled:opacity-50 ">
                            See your linked addresses
                        </a>
                    </div>
                    <p v-if="errorMessageLinkAddress" class="text-center text-red-500">{{ errorMessageLinkAddress }}</p>
                    <p v-if="successLinkedAddress" class="text-center text-green-500">{{ successLinkedAddress }}</p>
                </div>
                
                <div id="edit-user" class="card rounded-md border p-4 flex flex-col justify-center">
                    <h2 class="text-lg text-center">Change username</h2>
                    <div class=" flex flex-row flex-wrap justify-around my-10 mx-10 p-8 gap-4">
                        <div class="flex flex-col">
                            <label class="">Current username: {{ username }}</label>
                            <input type="text" v-model="newUsername" placeholder="New username"
                                class="username-input border-2 border-black px-4 pb-3 pt-8 text-align: center" />
                        </div>
                        <button @click="updateUsername"
                            class="bg-orange-400 text-white px-6 py-2 rounded font-bold hover:bg-orange-500 transition duration-300 text-md">
                            Update
                        </button>
                    </div>
                    <p v-if="errorMessageUserUpdate" class="text-center text-red-500">{{ errorMessageUserUpdate }}
                    </p>
                </div>


                <div id="logout" class="card rounded-md border p-4 flex justify-center gap-6">
                    <button @click="logout"
                        class="bg-orange-400 text-white px-6 py-2 rounded font-bold hover:bg-orange-500 transition duration-300 text-lg">
                        Logout
                    </button>
                    <!-- <button @click="deleteUser"
                        class="bg-orange-400 text-white px-6 py-2 rounded font-bold hover:bg-orange-500 transition duration-300 text-lg">
                        Delete user
                    </button> -->
                    <p v-if="errorMessageUserUpdate" class="text-center text-red-500">{{ errorMessageUserUpdate }}</p>
                </div>


                <div id="evm-addresses" v-if="evmAddressesList.length > 0"
                    class="card rounded-md border p-4 flex justify-center">
                    <div class="flex flex-col space-y-2 max-h-[150px] overflow-y-auto text-center">
                        <h3 class="text-center text-lg">EVM Addresses</h3>
                        <p v-for="address in shortenedEvmAddressesList" :key="address"
                            class="bg-orange-400 text-white py-2 text-xsm rounded font-semibold hover:bg-orange-500 transition duration-300 w-full px-3 text-center">
                            <span class="p-1 w-full inline-block break-all">{{ address }}</span>
                        </p>
                    </div>
                </div>


                <div id="sol-addresses" class="w-full flex justify-around mb-5 mt-8 card rounded-md sm:gap-3 gap-6 ">
                    <div v-if="solAddressesList.length > 0"
                        class="flex flex-col space-y-2 max-h-[150px] overflow-y-auto text-center">
                        <h3 class="text-center text-lg">SOL Addresses</h3>
                        <p v-for="address in shortenedSolAddressesList" :key="address"
                            class="bg-orange-400 text-white py-2 text-xsm rounded font-semibold hover:bg-orange-500 transition duration-300 w-full px-3 text-center">
                            <span class="p-1 w-full inline-block break-all">{{ address }}</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
h2 {
    font-weight: bold;
}

.name {
    font-weight: bold;
    font-size: 1.5em;
    /* Fuente divertida */
    color: #f97316;
    /* Un color alegre */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.card {
    margin: 2%;
    background-color: white;
    padding: 23px;
    box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.5);
    max-width: 603px;
    width: 100%;

}

.top-section,
.bottom-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.left {
    flex: 1;
    margin-right: 8px;
}

.right {
    display: flex;
    align-items: center;
}

.title {
    font-size: 40px;
    font-weight: bold;
    color: black;
}

.subtitle {
    color: black;
    font-size: 18px;
    font-weight: bold;
}

.rainbow {
    width: 100px;
    aspect-ratio: 70 / 45;
    background: linear-gradient(hotpink 12.5%, red 0 25%, orange 0 37.5%, yellow 0 50%, green 0 62.5%, darkturquoise 0 75%, blue 0 87.5%, indigo 0);
}

.separator {
    border-top: 10px solid black;
    margin: 10px 0;
}

.profile-picture {
    width: 150px;
    height: 170px;
    background-image: url('https://beracoin.app/images/main.png');
    background-size: cover;
    border-radius: 5px;
    margin-right: 20px;
}

.details {
    flex: 1;
    margin-left: 10px;
}

.row {
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: bold;
}

#badges {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.badge-background {
    width: 100%;
    height: 100%;
    background-size: cover;
    border-radius: 50%;
}

.badge {
    width: 75px;
    height: 75px;
    background-image: url('https://via.placeholder.com/30');
    background-size: cover;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.badge-rebases {
    background-image: url('https://i.seadn.io/gcs/files/132192066a920269bdff84178f6a2045.jpg?auto=format&dpr=1&w=512');
}

.badge-number {
    right: -5px;
    bottom: -5px;
    background-color: white;
    font-weight: bold;
    font-size: 20px;
    color: black;
    border-radius: 50%;
    min-width: 1.5em;
    height: 1.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.2em;
}

@media (max-width: 600px) {
    .profile-picture {
        width: 100px;
        height: 120px;
        margin-right: 10px;
    }

    .mob {
        padding: 1% !important;
        margin-top: 105px;
        margin-left: 5%;
        margin-right: 5%;
        max-width: -webkit-fill-available;
    }

    .badge {
        width: 45px;
        height: 45px;
        background-size: cover;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .card {
        margin: 0%;
        background-color: white;
        padding: 20px;
        box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.5);
        max-width: 532px;
        width: 100%;
    }

    .title {
        font-size: 30px;
        font-weight: bold;
        color: black;
    }

    .badge-number {
        top: 30px;
        left: 28px;
        position: absolute;
        background-color: white;
        font-weight: 300;
        font-size: 18px;
        color: black;

        /* Nuevas propiedades */
        border-radius: 50%;
        min-width: 1em;
        height: 1em;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.1em;
        line-height: 1;
    }
}

.score {
    padding: 2px 5px;
    border-radius: 3px;
}

.score.one {
    color: white;
    background-color: gray;
}

.score.two {
    color: black;
    background-color: lightblue;
}

.score.three {
    color: white;
    background-color: blue;
}

.score.four {
    color: white;
    background-color: purple;
}

.score.five {
    color: white;
    background-color: red;
}

#passport {
    margin-top: 30px;
}

</style>