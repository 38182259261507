export const NFT_LAYERS = {
  body: {
    name: 'Body',
    order: 3,
    items: [
    { 
      id: 'body-brown', 
      name: 'Brown', 
      url: new URL('@/assets/images/customNFT/body/body-brown.png', import.meta.url).href
    },
    { 
      id: 'body-white', 
      name: 'White', 
      url: new URL('@/assets/images/customNFT/body/body-white.png', import.meta.url).href
    },
    { 
      id: 'body-black', 
      name: 'Black', 
      url: new URL('@/assets/images/customNFT/body/body-black.png', import.meta.url).href
    }
    ]
  },
  background: {
    name: 'Background',
    order: 1,
    items: [
      { 
        id: 'bg-blue', 
        name: 'Blue', 
        url: new URL('@/assets/images/customNFT/background/background-blue.png', import.meta.url).href
      },
      { 
        id: 'bg-gay', 
        name: 'Gay', 
        url: new URL('@/assets/images/customNFT/background/background-gay.png', import.meta.url).href
      },
      { 
        id: 'bg-green', 
        name: 'Green', 
        url: new URL('@/assets/images/customNFT/background/background-green.png', import.meta.url).href
      },
      { 
        id: 'bg-pink', 
        name: 'Pink', 
        url: new URL('@/assets/images/customNFT/background/background-pink.png', import.meta.url).href
      },
      { 
        id: 'bg-purple', 
        name: 'Purple', 
        url: new URL('@/assets/images/customNFT/background/background-purple.png', import.meta.url).href
      },
      { 
        id: 'bg-red', 
        name: 'Red', 
        url: new URL('@/assets/images/customNFT/background/background-red.png', import.meta.url).href
      }
    ]
  },
  clothes: {
    name: 'Clothes',
    order: 3,
    items: [
      { 
        id: 'tshirt-blue', 
        name: 'Blue shirt', 
        url: new URL('@/assets/images/customNFT/clothes/tshirt-blue.png', import.meta.url).href
      },
      { 
        id: 'tshirt-gay', 
        name: 'Gay shirt', 
        url: new URL('@/assets/images/customNFT/clothes/tshirt-gay.png', import.meta.url).href
      },
      { 
        id: 'tshirt-green', 
        name: 'Green shirt', 
        url: new URL('@/assets/images/customNFT/clothes/tshirt-green.png', import.meta.url).href
      },
      { 
        id: 'tshirt-pink', 
        name: 'Pink shirt', 
        url: new URL('@/assets/images/customNFT/clothes/tshirt-pink.png', import.meta.url).href
      },
      { 
        id: 'tshirt-purple', 
        name: 'Purple shirt', 
        url: new URL('@/assets/images/customNFT/clothes/tshirt-purple.png', import.meta.url).href
      },
      { 
        id: 'tshirt-red', 
        name: 'Red shirt', 
        url: new URL('@/assets/images/customNFT/clothes/tshirt-red.png', import.meta.url).href
      },
      { 
        id: 'tshirt-naked', 
        name: 'Naked', 
        url: new URL('@/assets/images/customNFT/clothes/tshirt-blank.png', import.meta.url).href
      }
    ]
  },
  head: {
    name: 'Head',
    order: 4,
    items: [
      { 
        id: 'cap-blue', 
        name: 'Blue cap', 
        url: new URL('@/assets/images/customNFT/head/cap-blue.png', import.meta.url).href
      },
      { 
        id: 'cap-gay', 
        name: 'Gay cap', 
        url: new URL('@/assets/images/customNFT/head/cap-gay.png', import.meta.url).href
      },
      { 
        id: 'cap-green', 
        name: 'Gorra Verde', 
        url: new URL('@/assets/images/customNFT/head/cap-green.png', import.meta.url).href
      },
      { 
        id: 'cap-pink', 
        name: 'Pink cap', 
        url: new URL('@/assets/images/customNFT/head/cap-pink.png', import.meta.url).href
      },
      { 
        id: 'cap-purple', 
        name: 'Purple cap', 
        url: new URL('@/assets/images/customNFT/head/cap-purple.png', import.meta.url).href
      },
      { 
        id: 'cap-red', 
        name: 'Red cap', 
        url: new URL('@/assets/images/customNFT/head/cap-red.png', import.meta.url).href
      },
      { 
        id: 'cap-none', 
        name: 'No cap', 
        url: new URL('@/assets/images/customNFT/head/cap-blank.png', import.meta.url).href
      }
    ]
  }
};

// Configuración del cuerpo base
export const BASE_BODY = {
  url: new URL('@/assets/images/customNFT/body-1.PNG', import.meta.url).href,
  order: 2
};