// src/composables/useNFTComposition.js
import { ref, computed } from 'vue'
import { NFT_LAYERS, BASE_BODY } from '../config/layers'

export function useNFTComposition() {
    const selections = ref(Object.keys(NFT_LAYERS).reduce((acc, key) => {
        acc[key] = ''
        return acc
    }, {}))

    const isComplete = computed(() => {
        return Object.keys(selections.value).every(key => !!selections.value[key])
    })

    const loadImage = (url) => {
        return new Promise((resolve, reject) => {
            const img = new Image()
            img.crossOrigin = 'anonymous'
            img.onload = () => resolve(img)
            img.onerror = reject
            img.src = url
        })
    }

    const composeImage = async (canvas) => {
        const ctx = canvas.getContext('2d')
        ctx.clearRect(0, 0, canvas.width, canvas.height)

        const allLayers = [
            ...Object.entries(NFT_LAYERS).map(([key, layer]) => ({
                url: selections.value[key],
                order: layer.order,
                name: key
            })),
            { ...BASE_BODY, name: 'base-body' }
        ].filter(layer => layer.url)

        const orderedLayers = allLayers.sort((a, b) => a.order - b.order)
        for (const layer of orderedLayers) {
            try {
                const img = await loadImage(layer.url)
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
            } catch (error) {
                console.error(`Error loading ${layer.name}:`, error)
            }
        }
    }
    // Función para cargar el cuerpo base al inicio
    const initializeCanvas = async (canvas) => {
        if (!canvas) return
        await composeImage(canvas)
    }

    const downloadImage = async (canvas) => {
        const tempCanvas = document.createElement('canvas')
        const scale = 4
        tempCanvas.width = canvas.width * scale
        tempCanvas.height = canvas.height * scale

        await composeImage(tempCanvas)

        tempCanvas.toBlob((blob) => {
            const url = URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.download = 'my-nft.png'
            link.href = url
            link.click()
            URL.revokeObjectURL(url)
        }, 'image/png', 1.0)
    }

    return {
        selections,
        isComplete,
        composeImage,
        downloadImage,
        initializeCanvas
    }
}